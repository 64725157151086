<template>
  <div>
    <div style="background: #fff; color: black" class="bg_black" :class="$route.path == '/metas' ? 'py-3 min_h' : 'h-100'">
      <div class="row  d-flex align-items flex-column">
        <div class="rank">
          <img src="@/assets/image/metas/Selo1.svg" alt="Selo1.svg">
        </div>
        <div class="d-flex m-auto flex-column w-100" :class=" $route.path == '/metas' ? 'col-md-8 mb-3' : 'col-md-7 px-1 my-3' " id="gaugeCont">
            <!-- :value="aroundMath(totalMetas)" -->
            <!-- :gauge-color="scoreColor(aroundMath(totalMetas))" -->
          <VueSvgGauge
            :start-angle="-176"
            :end-angle="184"
            :value="aroundMath(totalMetas)"
            :separator-step="0"
            :separatorThickness="0"
            :min="0"
            baseColor="#E9E9EB"
            :max="100"
            :scale-interval="0"
            :gauge-color="[{ offset: 0, color: '#FED990'},{ offset: 50, color: '#E99637'}, { offset: 100, color: '#F38235'}]"
            :transition-duration="2500"
          >
            <div class="inner-text h-100 d-flex align-items-center justify-content-center" :style="`color:${scoreColor(aroundMath(totalMetas))}`" >
              <p style="color: #2474b2" class="mb-0"  v-if="porceto === 'Percent' " key="porcento" id="valueCircle">
                <span>{{ aroundMathFixed(totalMetas) }}</span>
                <small>%</small>
              </p>
              <p style="color: #2474b2; font-size: 23px;" class="mb-0"  v-else-if="porceto === 'money' " key="reais" >
                <!-- <small>R$</small> -->
                <span>{{ valueConvert(totalMetasReais) }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>
        <div class="d-flex align-items-center justify-content-around mt-3 containerMath">

          <div class="containerCircle d-flex align-items-center">
            <div class="circle mr-2"></div>
            <span>{{ aroundMathFixed(totalMetas) }}</span>
            <small>%</small>
          </div>
          <div class="containerCircle d-flex align-items-center">
            <div class="circle whiteCircle mr-2"></div>
            <span>{{ totalMetas > 100 ? 0 : roundFn( 100 - totalMetas, 1) }} </span>
            <small>%</small>
          </div>

        </div>
        <div class="d-flex mt-3 align-items-cente justify-content-around col-md-10 mx-auto userValue p" :class="$route.path == '/' ? 'mb-3' : ''">
           <div class="mr-2 d-flex align-items-center justify-content-center">
            <div class="image_div">
              <img
                :src="getImg(user.userLogin.photo)"
                class="img_user"
                @error="errorImage"
              />
            </div>
          </div>
          <p style="color: #868686" class="mb-0">
            {{ quebraNome($store.getters.getUser.name) }} atingiu
            <strong>{{ aroundMathFixed(totalMetas) }}%</strong> da meta total do mês.
          </p>
        </div>
        <div v-if="ticketAverage && ticketAverageLastMonth" class="col-md-10 mx-auto">
          <p class="ticket text-center py-3 mb-0"> 
            R$
            <strong>{{ ticketAverage | currency }}</strong>
            <span style="color: rgb(134, 134, 134)">
              Ticket Médio Mês Atual
            </span>
          </p>
          <p class="ticket text-center pb-3 mb-0">
            R$
            <strong>{{ ticketAverageLastMonth | currency }}</strong>
            <span style="color: rgb(134, 134, 134)">
              Ticket Médio Mês Anterior
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ticketAverage", "ticketAverageLastMonth", 'porceto'],
  data() {
    return {
      valuesPorcent: [
        {

          color: '#E9E9EB'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    quebraNome(val) {
      var str = val.split(" ");
      return str[0];
    },
  },
  computed: {
    totalMetas() {
      return this.$store.state.login.userScore;
    },
    totalMetasReais() {
      return this.$store.state.login.userOverallSold;
    },
    user() {
      return this.$store.state.login;
    },
  },
};
</script>

<style scoped>
.min_h {
  min-height: 220px;
}
p {
  color: #ffff;
  font-size: 10px;

  font-weight: 500;
}
.bg_black {
  /* min-height: 230px; */
  background: #333333;
  border-radius: 12px;
  border: 2px solid #ffffff;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
  width: 225px;
  /* height: 268px !important; */
}
.inner-text p {
  font-size: 42px;
  font-weight: bold;
}
small {
  font-size: 20px;
}
.ticket strong {
  color: #e0a638;
  font-size: 14px;
}

.rank {
  padding: 10px 30px 0;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: linear-gradient(270deg, #E0A638 0%, #F38235 100%);
}

.whiteCircle {
  background: #E9E9EB !important;
}

.containerCircle span, 
.containerCircle small {
  font-weight: 700;
  font-size: 12px;
  color: #2474B2 !important;
}

.containerMath {
  border-bottom: 2px solid #E9E9EB;
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 8px;
}

.userValue p {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #2474B2 !important;
  text-align: initial;
  /* margin-top: 10px; */
}

.image_div {
  border-radius: 50%;
  border: 3px solid #2474b2;
  height: 35px;
  width: 35px;
  overflow: hidden;
}
.image_div .img_user {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
@media screen and (max-width:767px){
  .bg_black{
    width: 100%;
  }
  .ticket{
    font-size: 16px;
  }
  .userValue p {
    font-size: 16px;
  }
  .gauge{
    width: 75%!important;
  }
  #gaugeCont{
    align-items: center;
  }
  #valueCircle{
    font-size: 36px!important;
  }
}
</style>