<template>
<div>
  <div class="col containePedidos">
    <div class="containeRequests">
      <nav class="d-flex borderBtmColor nav-pills" id="pills-tab" role="tablist">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="d-flex align-items-center containerPedidos nav-item nav-link active" role="presentation" id="pills-homePedidos-tab" data-toggle="pill" href="#pills-homePedidos" aria-controls="pills-homePedidos" aria-selected="true">
            <div class="circle mr-2" v-if="items.length > 0">
              {{ items.length }}
            </div>
            <h2 class="m-0">Pedidos em aberto</h2>
          </a>
          <a v-if="$store.getters.getBBPartner" class="d-flex align-items-center containerPedidos pl-3 nav-link nav-item" role="presentation" id="pills-profilePedidos-tab" data-toggle="pill" href="#pills-profilePedidos" aria-controls="pills-profilePedidos" aria-selected="false">
            <div class="circle mr-2" v-if="dataServiceBB.length > 0">{{ dataServiceBB.length }}</div>
            <h2 class="m-0">Pedidos Banco do Brasil</h2>
          </a>

        </div>
      </nav>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-homePedidos" role="tabpanel" aria-labelledby="pills-homePedidos-tab">

          <hr class="" />
          <div class="pl-3 pr-3 containerAberto">
            <h1 v-if="length < 1" class="pl-2">Nenhum serviço aberto</h1>
            <div class="container pb-3" v-for="item in items" :key="item.id">
              <div class="row d-flex align-items-center containerCard flex-nowrap">
                <p class="col p-0">#{{ item.id }}</p>
                <hr />
                <p class="col-sm-5 space" data-toggle="tooltip" data-placement="left" :title="item.ordersServices[0].serviceName">
                  {{ item.ordersServices[0].serviceName }}
                </p>
                <p class="col-sm-3 p-0">
                  R$ {{ valueConvertBR(item.ordersServices[0].value) }}
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-pane fade" id="pills-profilePedidos" role="tabpanel" aria-labelledby="pills-profilePedidos-tab">
          <div class="pl-3 pr-3 containerAberto">
            <hr class="" />
            <div class="container pb-3" v-for="item in dataServiceBB" :key="item.id">
              <div class="row d-flex align-items-center containerCard flex-nowrap">
                <p class="col p-0">#{{ item.id }}</p>

                <hr />
                <p class="col-sm-5 space" data-toggle="tooltip" data-placement="left" :title="item.ordersServices[0].serviceName">
                  {{ item.ordersServices[0].serviceName }}
                </p>
                <p class="col-sm-3 p-0">
                  R$ {{ valueConvertBR(item.ordersServices[0].value) }}
                </p>
               <div class="d-flex align-items-center">
                <button class="btn_defaultService btnMaxModal px-2 py-1 mx-2" @click="dataServiceItem(item)"  data-toggle="modal" data-target="#modaLInfoBB">
                  <img src="@/assets/image/icones/arrows-maximize.png" alt="arrows" />
                </button>
                <button class="btn_defaultService px-2 py-1" @click="sendFinish(item)">
                  Finalizar Pedido
                </button>
               </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <modalBB :list="dataService"/>
    <div class="containerBtn">
      <router-link tag="button" to="/pedidos" class="watchAll">Ver todos <img src="@/assets/image/metas/arrow.svg" alt="arrow.svg" /></router-link>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapActions, mapMutations
} from 'vuex'
import modalBB from '../../finalizar_pedidos/container_servico/modalBB.vue'

export default {
  props: ['items', 'length'],
  components: {
    modalBB
  },
  data () {
    return {
      showBB: true,
      dataServiceBB: [],
      dataService: []
    }
  },
  mounted () {
    this.requestItens()
  },
  methods: {
    ...mapActions(['requestServiceBB']),
    ...mapMutations(['setItemRequest']),
    async requestItens () {
      try {
        const {
          data
        } = await this.requestServiceBB()
        this.dataServiceBB = data.result
      } catch (error) {
        console.log(error)
      }
    },
    dataServiceItem (item) {
      this.dataService = item
      console.log(item)
    },
    sendFinish (item) {
      const { ordersServices } = item
      console.log(item)
      this.setItemRequest(item)
      this.$router.push('/finalizar-servicoBB')
      if (ordersServices[0].link) {
        console.log(ordersServices[0].link)
      } else {
        // nao tem link
      }
    }
  }
}
</script>

<style scoped>
.containePedidos {
  height: 290px;
}

.containerCard {
  background: #f9f9f9;
  border-radius: 4px;
  margin: 0 auto;
  /* width: 100%; */
}

.containerCard p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #797984;
}

.space {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 0;
}

::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #f38235;
  border-radius: 10px;
  border: none;
}

.containerCard hr {
  margin: 0 9px;
  height: 33px;
  background: #c4c4c4;
  width: 1px;
}

.containeRequests {
  height: 100%;
  background: #fff;
  border-radius: 12px;
  max-height: 300px;
  margin-bottom: 8px;
  overflow: auto;
}

.containeRequests .circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #f38235;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containeRequests h1 {
  color: #f38235;
}

.containeRequests h1,
.containeRequests h2 {
  color: #f38235;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

}

.containerBtn {
  width: 100%;
  display: flex;
}

.watchAll {
  background: none;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #98c4e6;
  margin: 0 0 0 auto;
  cursor: pointer;
  z-index: 333;
  outline: none;
}

.containerPedidos {
  cursor: pointer;
}

.borderBtmColor {
  width: 90%;
  margin: 10px auto 0;
  border-bottom: 5px solid #f3823552;
}

.nav-link {
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  border: none;
}

.nav-link.active {
  color: #fff !important;
  padding: 10px;
  background-color: #f3823552 !important;
  border-bottom: 0px solid;
  border-radius: 0;
  background: none;
}

.btn_defaultService {
  border: none;
  background: #f37018;
  color: #fff;
  /* width: 107px; */
  font-size: 12px;
  border-radius: 5px;
  outline: none;
}

.btn_defaultService:hover {
  background: #f37018af;
}
.btnMaxModal {
  background: #f3701800;
}

.btnMaxModal:hover {
  background: #f0eeedf5;
}
@media (min-width: 1400px) {
  .containerAberto {
    max-width: 100%;
  }
}
</style>
