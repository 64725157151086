<template>
  <div class="col-4 px-2">
    <div
      class="box row d-flex justify-content-center align-items-center mx-0"
      v-if="!item.name"
    >
      <HalfCircleSpinner color="#f38235" :size="50" />
    </div>

    <router-link
      :to="`/servico/${item.id}`"
      class="box row align-items-center mx-0"
      v-else
    >
      <div class="col-md-10 mx-auto text-center">
        <h4 class="meta_h4 mb-0">{{ item.name }}</h4>
        <p class="meta_p mb-0">{{ item.category }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
export default {
  components: { HalfCircleSpinner },
  props: ["item"],
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {}
};
</script>

<style scoped>
.box {
  border-radius: 12px;
  height: 110px;
  background-color: #ffffff;
  transition: 0.2s ease-in-out;
  flex: 1;
  outline: 2px solid #f08736;
}

h4 {
  color: #2474b2;
}

.meta_h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #2474b2;
}

.meta_p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #f38235;
}

.box:hover {
  transform: translateY(-5px);
  /* -webkit-box-shadow: 5px 7px 10px 1px rgba(0,0,0,0.39);
  box-shadow: 5px 7px 10px 1px rgba(0,0,0,0.39); */
}
</style>
