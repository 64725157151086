<template>
  <div>
    <div class="container m-0">
      <!-- <h2>Ticket Médio</h2> -->
      <div class="main pt-2">
        <div>
          <ul class="nav nav-pills mb-3 tabNav" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                >Vendedor</a
              >
            </li>
            <li class="nav-item" role="presentation" v-if="ticketAll">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                >Franquia</a
              >
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <p>
                R$
                <strong>{{
                  valueConvertBR(ticketStore.ticketAverageUser)
                }}</strong>
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <p>
                R$
                <strong>{{ valueConvertBR(ticketStore.ticketAverage) }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ticketAll", "ticketStore"]
};
</script>

<style scoped>
.container {
  width: 300px;
  height: 145px;
  background: #fff;
  background: #ffffff;
  border-radius: 12px;
}

.container h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2474b2;
  padding: 10px 0 10px 0;
  border-bottom: 1.04934px solid #98c4e6;
}

.container p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #92bad9;
  margin: 25px 0;
}

.container p strong {
  font-weight: 700;
  font-size: 38px;
  color: #2474b2;
}

.nav-link {
  color: #98c4e6;
  font-weight: 700;
  font-size: 14px;
}

.nav-link.active {
  color: #3f88c1;
  background-color: none;
  border-bottom: 6px solid;
  border-radius: 0;
  background: none;
}

.tabNav {
  border-bottom: 2px solid #98c4e6;
}
</style>
